<template>
  <div class="portfolio__input">
    <Input
      header="Название файла"
      placeholder="Название"
      v-model="formData.title"
    />
    <Dropdown
      header="Год"
      placeholder="Выберите из списка"
      :items="year"
      :keys="year"
      v-model="formData.year"
    />
  </div>
  <DocsUpload
    label="Загрузить портфолио"
    keyName="image"
    currentDocsOrientation="free"
    :file-loaded="clearFile"
    type="portfolio"
  />

  <Button
    class="portfolio__btn"
    @click="savePortfolio"
    :loading="isBtnLoading"
    :disabled="!isFormFilled"
    v-if="!project"
  >
    Добавить в портфолио
  </Button>
</template>

<script>
import Input from "@/components/Blocks/Input.vue";
import Dropdown from "@/components/Blocks/Dropdown.vue";
import DocsUpload from "@/components/Blocks/DocsUpload.vue";
import useVuelidate from "@vuelidate/core";
import moment from "moment/moment";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import validations from "@/enums/validations";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import { mapState } from "vuex";
import Button from "@/components/Blocks/Button.vue";
export default {
  name: "PortfolioForm",
  components: { Button, DocsUpload, Dropdown, Input },
  props: { project: Boolean },
  emits: ["formFilled"],
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      isBtnLoading: false,
      clearFile: undefined,
      formData: {
        image: "",
        year: "",
        title: "",
      },
      itemForPush: {},
    };
  },
  watch: {
    docs: {
      handler: function () {
        this.formData.image = this.docs["image"];
        this.clearFile = undefined;
      },
      deep: true,
    },
    formData: {
      handler: async function () {
        if (this.project && this.isFormFilled) {
          await this.displayBlob(this.formData?.image);

          this.$emit("formFilled", this.itemForPush, this.formData);
        }
      },
      deep: true,
    },
  },
  validations() {
    return { formData: validations.portfolioData };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.portfolioData;
    },
    year() {
      return Array.from(Array(2).keys())
        .map((item, index) => {
          item = moment().subtract(index, "year").year();
          return item;
        })
        .sort((a, b) => {
          return b - a;
        });
    },
    isFormFilled() {
      return Object.values(this.formData).every((item) => {
        return !!item;
      });
    },
    ...mapState(["docs", "portfolioList", "portfolioListLength"]),
  },
  methods: {
    async savePortfolio() {
      if (!this.isFormFilled) return;

      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }

      let payloadObject = {};
      Object.keys(this.formData).forEach((item) => {
        return (payloadObject[item] = this.formData[item]);
      });

      this.isBtnLoading = true;
      await this.displayBlob(this.formData?.image);

      await createRequest(requestConfigs.POSTSavePortfolio, {
        jsonPayload: payloadObject,
      })
        .then(() => {
          this.$store.dispatch("pushToPortfolioList", this.itemForPush);
          this.$store.commit(
            "setPortfolioListLength",
            this.portfolioList.length
          );
        })
        .then(() => {
          this.itemForPush = {};
          Object.keys(this.formData).forEach((item) => {
            return (this.formData[item] = "");
          });
          this.clearFile = false;
          this.$store.commit("setDocument", ["", "image"]);
        })
        .then(() => {
          this.$store.commit("pushToTray", {
            text: "Данные успешно сохранены",
            type: "success",
          });
        })
        .catch((error) => {
          this.$store.commit("pushToTray", {
            text: error.errors[0].error_descr,
            type: "error",
          });
        })
        .finally(() => {
          this.isBtnLoading = false;
        });
    },
    async displayBlob(e) {
      const image = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(e);
      });
      this.itemForPush = {
        push_image: image,
        created_at: moment(),
        title: this.formData?.title,
        year: this.formData?.year,
        isPush: true,
      };
    },
    moment,
  },
};
</script>

<style lang="scss"></style>
