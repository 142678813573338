<template>
  <div class="docs-upload" :class="className">
    <div class="docs-upload--left">
      <p>
        {{ `${label}${isRequired ? "*" : ""}` }}
        <span v-if="fileChanged">(не сохранено)</span>
      </p>
    </div>
    <div class="docs-upload--right">
      <div class="docs-upload__file">
        <div
          v-if="fileLoaded ?? !!docs[keyName]"
          class="docs-upload__file-text"
        >
          <a v-if="linkShown(keyName)" @click="openImg" target="_blank">
            Файл загружен
          </a>

          <span v-else>Файл загружен</span>
          <Icon
            :type="fileChanged ? 'Warning' : 'ok'"
            v-if="!error"
            :data-tooltip="
              fileChanged && !fileLoaded ? undefined : 'Файл успешно загружен'
            "
          />
          <Icon
            class="docs-upload__icon-status"
            v-if="linkShown(keyName)"
            :type="fileChanged ? 'Sync' : currentIcon"
            :style="
              fileChanged ? { color: '#595959' } : { color: currentIconColor }
            "
            :data-tooltip="
              fileChanged ? 'Документ ожидает проверки' : currentIconTooltip
            "
          />
        </div>
        <div v-else>Файл не выбран</div>

        <Icon
          v-if="infoText"
          type="Help"
          :data-tooltip="infoText"
          class="docs-upload__tooltip"
        />
      </div>
      <InputFile
        :currentDocsOrientation="currentDocsOrientation"
        :keyName="keyName"
      >
        Выбрать файл
      </InputFile>
      <p class="docs-upload__info">
        *файлы с разрешением png, jpg не более 2 Мб
      </p>
    </div>
    <PopupViewCertificate
      v-if="isPopupOpen"
      :image_url="img_url"
      @close-popup="isPopupOpen = false"
    />
  </div>
</template>

<script>
import InputFile from "@/components/Blocks/InputFile";
import { mapGetters, mapState } from "vuex";
import Icon from "@/components/Blocks/Icon";
import PopupViewCertificate from "@/components/Popups/PopupViewCertificate";
export default {
  name: "DocsUpload",
  components: { PopupViewCertificate, Icon, InputFile },
  data() {
    return {
      isPopupOpen: false,
      img_url: "",
      fileChanged: false,
      img_blob: "",
    };
  },
  props: {
    hasFile: Boolean,
    label: [String, Number],
    currentDocsOrientation: String,
    keyName: String,
    fileLoaded: [Boolean, String],
    error: Boolean,
    infoText: String,
    itemType: {
      type: String,
      default: "education",
    },
    type: {
      type: String,
      default: "education",
    },
    isRequired: Boolean,
    project: Boolean,
  },
  watch: {
    docs: {
      handler: function () {
        this.img_blob = this.docs[`${this.keyName}`];
      },
      deep: true,
    },
    img_blob(value) {
      if (this.type === "portfolio") return;

      this.fileChanged = Boolean(value);
    },
    // link() {
    //   this.fileChanged = false;
    // },
    // fileChanged(value) {
    //   console.log(value);
    // },
  },
  methods: {
    async openImg() {
      if (this.user[this.itemType]?.document_files) {
        this.img_url =
          this.user[this.itemType]?.document_files[this.keyName]?.full;
      }
      this.isPopupOpen = true;
    },
    openPopup() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupCropper",
        isShown: true,
        props: {},
      });
    },
    linkShown(value) {
      if (this.user[this.itemType]?.document_files) {
        return this.user[this.itemType]?.document_files[value]?.full;
      }
    },
  },
  computed: {
    link() {
      let link;
      if (
        this.user[this.itemType] &&
        this.user[this.itemType]?.document_files
      ) {
        !this.hashMedia
          ? (link =
              this.user[this.itemType]?.document_files[this.keyName]?.full)
          : (link = `${process.env.VUE_APP_BACKEND_URL}${
              this.user[this.itemType]?.document_files[this.keyName]?.full
            }`);
      }
      return link;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "docs-upload--has-file": this.hasFile,
        "docs-upload--error": this.error,
        "docs-upload--loaded": this.fileLoaded,
        "docs-upload--new": this.fileChanged,
      };
    },
    statusDocument() {
      let status = {};
      if (
        (this.user[this.itemType]?.document_files[this.keyName]?.full ||
          this.docs[this.keyName]) &&
        this.user.info_validation_documents?.data?.length > 0
      ) {
        if (
          this.user.info_validation_documents?.data?.find((item) => {
            return item.key_document === this.keyName;
          })
        ) {
          status = this.user.info_validation_documents?.data?.find((item) => {
            return item.key_document === this.keyName;
          });
        }
      }
      return status;
    },
    currentIcon() {
      let icon = "Sync";
      if (this.project && this.statusDocument?.status === 40) {
        icon = "Cancel";
      }
      if (this.project && this.statusDocument?.status === 50) {
        icon = "Confirm-Circle";
      }
      return icon;
    },
    currentIconColor() {
      let color = "#595959";
      if (this.project && this.statusDocument?.status === 40) {
        color = "red";
      }
      if (this.project && this.statusDocument?.status === 50) {
        color = "green";
      }
      return color;
    },
    currentIconTooltip() {
      if (this.statusDocument.status === 40) {
        return "Документ отклонён модераторами. Необходимо загрузить корректный документ и подать заявку.";
      } else if (this.statusDocument.status === 50) {
        return "Документ принят модераторами.";
      } else
        return (
          this.docsStatus[this.statusDocument?.status] ??
          "Документ ожидает проверки"
        );
    },
    ...mapState(["docs"]),
    ...mapState("user", ["user"]),
    ...mapGetters(["hashMedia", "docsStatus"]),
  },
};
</script>

<style lang="scss"></style>
